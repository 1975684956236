import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SignupComponent } from './components/signup/signup.component';
import { ProfileComponent } from './components/profile/profile.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AuthGuardService } from './services/authguard/auth-guard.service';
// import { RealtorSignupComponent } from './components/realtor-signup/realtor-signup.component';
import { NewRealtorSignupComponent } from './components/new-signup/new-signup.component';
import { PricingPlansComponent } from './components/pricing-plans/pricing-plans.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { ConsumerSignupComponent } from './components/consumer-signup/consumer-signup.component';
import { ProfessionalInfoComponent } from './components/professional-info/professional-info.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { OpenHouseComponent } from './components/open-house/open-house.component';
import { IntanceAccessComponent } from './components/intance-access/intance-access.component';
import { ListingsComponent } from './components/listings/listings.component';
import { OpenHouseSigninSummaryComponent } from './components/open-house-signin-summary/open-house-signin-summary.component';
import { NewLandingComponent } from './components/new-landing/new-landing.component';
import { NoConsumerLoginComponent } from './components/no-consumer-login/no-consumer-login.component';
import { MobileOpenHouseComponent } from './components/mobile-open-house/mobile-open-house.component';
import { RealtorsMembershipDetailsComponent } from './components/realtors-membership-details/realtors-membership-details.component';
import { ProfessionalMembershipDetailsComponent } from './components/professional-membership-details/professional-membership-details.component';
import { RenewMembershipPlanComponent } from './components/renew-membership-plan/renew-membership-plan.component';
import { RenewMembershipPlanProfessionalComponent } from './components/renew-membership-plan-professional/renew-membership-plan-professional.component';
import { TestimonialComponent } from './components/testimonial/testimonial.component';
import { SearchvaluemiiComponent } from './components/searchvaluemii/searchvaluemii.component';
import { LeadsComponent } from './components/leads/leads.component';
import { ListingsShowingRealtorsComponent } from './components/listings-showing-realtors/listings-showing-realtors.component';
import { RealtorMembershipUpgradeComponent } from './components/realtor-membership-upgrade/realtor-membership-upgrade.component';
import { RealtorProfessionalInviteComponent } from './components/realtor-professional-invite/realtor-professional-invite.component';
import { RealtorTestimonialComponent } from './components/realtor-testimonial/realtor-testimonial.component';
import { RealtorTemplatesComponent } from './components/realtor-templates/realtor-templates.component';
import { PostcardPaymentComponent } from './components/marketMii/postcard-payment/postcard-payment.component';
import { OnboardComponent } from './components/onboard/onboard.component';
import { PostcardPaymentNotificationsComponent } from './components/marketMii/postcard-payment-notifications/postcard-payment-notifications.component';
import { UpgradePaymentComponent } from './components/upgrade-payment/upgrade-payment.component';
import { AddZipCodesComponent } from './components/add-zip-codes/add-zip-codes.component';
import { CertificateHomeValuationComponent } from './components/certificate-home-valuation/certificate-home-valuation.component';

const routes: Routes = [
  { path: 'prosignup/:regCode', component: SignupComponent },
  { path: 'prosignup', component: SignupComponent },
  { path: 'realtorsignup', component: NewRealtorSignupComponent },
  { path: 'consumersignup/:regCode', component: ConsumerSignupComponent },
  { path: 'consumersignup', component: ConsumerSignupComponent },
  { path: 'dashboard/open-house', component: MobileOpenHouseComponent },
  { path: 'consumer/client', component: NoConsumerLoginComponent },
  { path: 'upgrade/:code', component: RealtorMembershipUpgradeComponent, pathMatch: 'full' },
  { path: 'professional/join/:code', component: RealtorProfessionalInviteComponent, pathMatch: 'full' },
  { path: 'testimonial/:code', component: TestimonialComponent },
  { path: 'valuemii', component: SearchvaluemiiComponent },
  { path: 'home', component: LandingPageComponent },
  { path: 'home/:uniqueId', component: LandingPageComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService] },
  { path: 'dashboard/:token', component: DashboardComponent },
  { path: 'listings', component: ListingsComponent, canActivate: [AuthGuardService] },
  { path: 'realtor-instant-access', component: IntanceAccessComponent, canActivate: [AuthGuardService] },
  { path: 'open-house', component: OpenHouseComponent, canActivate: [AuthGuardService] },
  { path: 'open-house-signin-summary', component: OpenHouseSigninSummaryComponent, canActivate: [AuthGuardService] },
  { path: 'membership/realtors', component: RealtorsMembershipDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'templates', component: RealtorTemplatesComponent, canActivate: [AuthGuardService] },
  { path: 'renew-plan-realtors', component: RenewMembershipPlanComponent, canActivate: [AuthGuardService] },
  { path: 'membership/professional', component: ProfessionalMembershipDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'renew-plan-professional', component: RenewMembershipPlanProfessionalComponent, canActivate: [AuthGuardService] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService] },
  { path: 'listing-showings', component: ListingsShowingRealtorsComponent, canActivate: [AuthGuardService] },
  { path: 'leads', component: LeadsComponent, canActivate: [AuthGuardService] },
  { path: 'pricingPlans', component: PricingPlansComponent, canActivate: [AuthGuardService] },
  { path: 'logout', component: LogoutComponent, canActivate: [AuthGuardService] },
  { path: 'forgotPassword', component: ForgotPasswordComponent, canActivate: [AuthGuardService] },
  { path: 'resetPassword', component: ResetPasswordComponent, canActivate: [AuthGuardService] },

  /* Signup Success */
  { path: 'signup-success', component: LandingPageComponent },

  /* No Or Custom Footer Component */

  { path: 'professional-info', component: ProfessionalInfoComponent },
  { path: 'instant-access', component: NewLandingComponent },
  { path: 'testimonials', component: RealtorTestimonialComponent, canActivate: [AuthGuardService], pathMatch: 'full' },

  /* Domii White Label */
  { path: 'onboard/:code', component: OnboardComponent, pathMatch: 'full' },
  {
    path: 'admin',
    loadChildren: () => import(`./components/whitelabelling/whitelabelling.module`).then(
      module => module.WhitelabellingModule
    )
  },

  /* MarketMii */
  { path: 'postcard/payment/:code', component: PostcardPaymentComponent, pathMatch: 'full' },
  { path: 'postcard/messages', component: PostcardPaymentNotificationsComponent, pathMatch: 'full' },

  /* Upgrade Payment */
  { path: 'upgrade/payment/:code', component: UpgradePaymentComponent},

  /* Desired Zip Codes Form */
  { path: 'add-zip-codes', component: AddZipCodesComponent},

  /* Certificate Home Valuation */
  { path: 'lead/:leadid', component: CertificateHomeValuationComponent},

  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      preloadingStrategy: PreloadAllModules
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
